import React from "react"
import { Link } from "gatsby";

import LocationBar from '../components/location-bar'
import MessageAngelica from '../components/message-angelica'

const MessageAngelicaPage = ({data}) => {
  return (
      <main>
      <LocationBar />
      <h2 style={{textAlign: "center"}}>Send a Message to Angelica!</h2>
      <MessageAngelica />
      <Link to="/" style={{position:"absolute", bottom: 0, left: 0, padding: "1em", background:"var(--primary_color)", color: "var(--primary_bg)"}}>go back home</Link>
      </main>
  )
}

export default MessageAngelicaPage
