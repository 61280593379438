import React, {
  useState
} from 'react';

import {
  navigateTo
} from 'gatsby-link';

import maStyle from './message-angelica.module.css'

export default () => {
  const [formState, setFormState] = useState({
    comment: '',
    email: ''
  });

  const handleInput = (e) => {
    e.preventDefault();
    let newState = {
      ...formState
    };
    newState[e.target.id] = e.target.value;
    setFormState({
      ...newState
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...formState
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  return (
      <div className={maStyle.contact}>
      <form
        name="message-from-mocka"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="message-from-mocka" />
        <div className={maStyle.formGroup}>
          <label for="mockaMessage">What's Up, Mocka?</label>
          <textarea name="mockaMessage" placeholder="type your message here..." id="mockaMessage" value={formState.mockaMessage} onInput={handleInput}></textarea>
        </div>
        <input type="submit" value="Click Here to Send Message!"/>
      </form>
      </div>
  );
};

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
